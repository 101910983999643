import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ExternalView from '../views/ExternalView.vue';
import LoginView from '../views/LoginView.vue';
import NotFound from '../views/NotFound.vue';
import authRouter from '@/modules/auth/router';
import businessPartnersRouter from '@/modules/business-partners/router';
import dashboardRouter from '@/modules/dashboard/router';
import documentationCenterRouter from '@/modules/documentation-center/router';
import helpRouter from '@/modules/help/router';
import managementRouter from '@/modules/management/router';
import productionRouter from '@/modules/production/router';
import reportsRouter from '@/modules/reports/router';
import settingsRouter from '@/modules/settings/router';
import settlementBillingRouter from '@/modules/settlement-billing/router/index';
import settlementBillingClientRouter from '@/modules/settlement-billing/router/indexClient';
import { authGuard } from '@auth0/auth0-vue';
import managementApi from '@/api/managementApi';
import store from '@/store/index';
import moment from 'moment';

const routes = [
    {
        path: '/auth',
        component: LoginView,
        beforeEnter: authGuard,
        children: [
            {
                path: '/auth',
                name: 'auth',
                ...authRouter,
            },
        ],
    },
    {
        path: '/',
        component: HomeView,
        beforeEnter: authGuard,
        children: [
            {
                path: 'business-partners',
                name: 'business-partners',
                ...businessPartnersRouter,
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                ...dashboardRouter,
            },
            {
                path: 'documentation-center',
                name: 'documentation-center',
                ...documentationCenterRouter,
            },
            {
                path: 'help',
                name: 'help',
                ...helpRouter,
            },
            {
                path: 'management',
                name: 'management',
                ...managementRouter,
            },
            {
                path: 'production',
                name: 'production',
                ...productionRouter,
            },
            {
                path: 'reports',
                name: 'reports',
                ...reportsRouter,
            },
            {
                path: 'settings',
                name: 'settings',
                ...settingsRouter,
            },
            {
                path: 'settlement-billing',
                name: 'settlement-billing',
                ...settlementBillingRouter,
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'NotFound',
                component: NotFound,
            },
            {
                path: '',
                redirect: () => {
                    const lastVisitedRoute =
                        localStorage.getItem('lastVisitedRoute');
                    return lastVisitedRoute && lastVisitedRoute !== '/auth'
                        ? { path: lastVisitedRoute }
                        : { name: 'info-cards' };
                },
            },
            // {
            //   path: 'management/functional-structure/activities/add-more-fields',
            //   name: 'add-more-fields',
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "add-more-fields" */
            //       '@/modules/management/modules/functional-structure/activities/AddMoreFields.vue'
            //     ),
            // },
        ],
    },
    {
        path: '/external',
        component: ExternalView,
        children: [
            {
                path: 'settlement-billing',
                name: 'settlement-billing',
                ...settlementBillingClientRouter,
            },
        ],
    },
    {
        path: '/register',
        name: 'register',
        beforeEnter: authGuard,
        component: () =>
            import(
                /* webpackChunkName: "register" */ '@/views/RegisterOrganization.vue'
            ),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const getSessionId = async (sessionId) => {
    let response = null;
    if (sessionId !== undefined) {
        const { data } = await managementApi.get(`Sessions/${sessionId}`);
        response = data;
    }

    return response;
};

const GetDiffHoursDates = (dateAuditTrailSession) => {
    return moment()
        .utc()
        .diff(moment(dateAuditTrailSession).utc(), 'hours', true);
};

const closeSessionByUser = async (userId) => {
    const { data } = await managementApi.put(
        `Sessions/UpdateSessionByUser/${userId}`,
    );
};

const AdminSessionUser = async () => {
    //admin session user
    const sessionId = localStorage.getItem('sessionId') ?? undefined;
    const userAuth = store.state.auth.userDB.userId ?? undefined;
    let isActiveSession = true;

    if (sessionId !== undefined && userAuth !== undefined) {
        getSessionId(sessionId).then((session) => {
            if (session?.data !== null) {
                if (session?.data?.isActive === false) {
                    isActiveSession = false;
                    localStorage.removeItem('sessionId');
                    setTimeout(() => {
                        router.push({ name: 'auth' });
                        const time = setTimeout(() => {
                            if (window.location.pathname === '/auth') {
                                localStorage.removeItem('issaveversion');
                                window.location.reload();
                            }
                            clearTimeout(time);
                        }, 1000);
                    }, 0);
                }

                //solo si la sesión dle usuario esta activa
                if (isActiveSession == true) {
                    let dateSessionAuditTrail = session?.data?.dateSessionAudit;
                    let resultDates = GetDiffHoursDates(dateSessionAuditTrail);

                    if (resultDates >= 4) {
                        const result2 = closeSessionByUser(userAuth);
                        // console.log('Result2: ', result2);
                        localStorage.removeItem('sessionId');
                        setTimeout(() => {
                            router.push({ name: 'auth' });
                            const time = setTimeout(() => {
                                if (window.location.pathname === '/auth') {
                                    localStorage.removeItem('issaveversion');
                                    window.location.reload();
                                }
                                clearTimeout(time);
                            }, 1000);
                        }, 0);
                    }
                }
            }
        });
    }
};

router.beforeEach((to, from, next) => {
    var modified = localStorage.getItem('inititalStateModified');
    if (modified != 'false' && modified !== null) {
        if (
            confirm(
                'Se ha detectado que se han realizado cambios y no se ha guardado. ¿Está seguro que desea cerrar el formulario?',
            )
        ) {
            localStorage.setItem('inititalStateModified', false);
            next();
        }
    } else {
        localStorage.setItem('lastVisitedRoute', to.path);
        localStorage.setItem('currentPage', to.name);
        localStorage.setItem('inititalStateModified', false);
        next();
    }

    //admin session
    AdminSessionUser();
});

export default router;

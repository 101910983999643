export default () => ({
    params: JSON.parse(sessionStorage.getItem('m_params')),
    listHitosActivities: [],
    isLoading: false,
    stateMenu: true,
    activeMenu: '',
    activeModule: '',
    activeSubModule: '',
    activities: [],
    companies: [],
    categories: [],
    flows: [],
    alerts: [],
    dynamicForms: [],
    users: [],
    userFormState: {},
    fullAreasUsuario: {},
    areasByUser: [],
    areasByUserSelected: [],
    workGroups: [],
    roles: [],
    rolesForm: [],
    contacts: [],
    contactsInfoState: {},
    clientsByContact: [],
    grid: null,
    grid2: null,
    customers: [],
    customerFormState: {},
    contactsByClient: [],
    gridCustomers: null,
    enterpriseGroups: [],
    projects: [],
    projectForm: {},
    tradeAgreements: [],
    bills: [],
    apoyoOnlyItems: [],
    notificationsTabUser: [],
    projectActivities: [],
    stringWorkflow: '',
    actvititesTypeByAreaIdAndSubAreaIdAndState: [],
    actionTypeWorkflows: '',
    workFlowsCreateOrEditObject: {
        id: '',
        companyId: '',
        name: '',
        areaId: '',
        areaName: '',
        subAreaName: '',
        subAreaId: '',
        code: '',
        dynamicForm: '',
        description: '',
        activities: [{}],
        definition: '',
        beginTime: 1,
    },
    actionToExecuteAfterClosePopUpDiagram: 1,
    dynamicFormsToWorkflows: [],
    orgChartCreateOrEditObject: {
        id: '',
        IdCompany: '',
        name: '',
        code: '',
        description: '',
        members: [],
        definition: '',
        active: true,
    },
    actionTypeOrgChart: 0,
    usersToOrgChart: [],
    memberModified: null,
    actionToExecuteAfterChargeOrgChart: 0,
    dynamicFieldsByForm: null,
    dynamicForm: {
        active: true,
        id: '',
        name: '',
        description: '',
        infoWorkflows: [],
        fields: [],
        companyId: '',
    },
    tradeAgreementsForm: {},
    tradeAgreementIdForView: null,
    projectIdForView: null,
    typeExpenses: [],
    infoUserOff: null,
    usersReasign: [],
    saveReasign: false,
    jobManagement: {},
    projectsWithNameClient: [],
    activitySubAreaByProject: [],
    companyHolidays: [],
    workflowsWithAresaAndSubAreas: [],
    workflowsWithAresaAndSubAreasInter: [],
    fieldsOfCustomItemComponent: [],
    customPopupOnClose: null,
    selectedPreinvoices: [],
    workGroupsForDropdown: [],
    usersByWorkGroupsForDropdown: [],
    companyActiveId: localStorage.getItem('companyId'),
    updateTypeCompany: {},
    workFlowsByDynamicForm: [],
    processWithNameAndIdForDropdown: [],
    dynamicFormsValue: null,
    customFieldsValue: null,
    dataToRenderizeIntoDynamicForm: [],
    workGroupsByUser: [],
    allPermissions: [],
    tradeAgreementsIds: [],
    seletedCompany: {
        id: localStorage.getItem('companyId'),
        name: localStorage.getItem('companyActiveName'),
    },
    mainPrincipalCompanyPopUp: false,
    historialById: [],
    typeNotifications: [],
    contactByClient: [],
    proyectActiveId: '',
    paramsTradeAgreement: JSON.parse(
        sessionStorage.getItem('m_paramsTradeAgreement'),
    ),
    tradeAgreementFrom: {},
    datesTradeAgreement: {},
    paramsBills: JSON.parse(sessionStorage.getItem('m_paramsBills')),
    activeStepModule: '',
    ShowpopupWG: false,
    systemFormState: {},
    infoCompanyById: {},
    currencies: [],
    currency: [],
    Indexingunit: [],
    objindexingbyid: {},
    practicesObjListAll: [],
    practicesObjList: [],
    objpracticesbyid: {},
    proyectActiveName: localStorage.getItem('proyectActiveName'),
    companyActiveName: localStorage.getItem('companyActiveName'),
    companyProcess: localStorage.getItem('companyProcess'),
    usersAdded: [],
    usersRemoved: [],
    subareaslist: [],
    areaslist: [],
    areabyid: {},
    activeLevel: '',
    filesgeneratebyaws: [],
    listFilesloaded: [],
    objfilesuploadS3: [],
    ispermittedfile: false,
    Idsgeneratebyaws: [],
    listadFilesXcomponente: [],
    selectedUsersOnShareTab: [],
    areaSubAreaByActivityId: '',
    currentCompaniesByUser: [],
    statusFiles: [],
    notificationsFlowData: [],
    flowAlertsParams: JSON.parse(localStorage.getItem('m_flowAlertsParams')),
    statusFilesTask: [],
    selectedUsersExternal: [],
    externalUsersCount: 0,
    externalUsersOnlyItems: [],
    setExternalDataUsers: [],
    reverseToogle: null,
    areasByUsernewComponent: [],
    dynamicFieldsNames: [],
    usersOutlook: [],
    dynamicReports: [],
    selectCompanyManagementId:
        localStorage.getItem('selectCompanyManagementId') ?? '',
    selectCompanyManagementName:
        localStorage.getItem('selectCompanyManagementName') ?? '',
    dynamicSaveReasign: false,
    chekedReasign: false,
    userOldId: null,
    documentsByCase: [],
    stepsMenuState: [],
    activityState: null,
    subAreasTariff: [],
    confirmDeactivateProyectsClients: false,
    confirmDeactivateProyects: false,
    dataClient: null,
    dataProyect: null,
    stateDeactivateClients: false,
    verifyDeactivateClients: false,
    stateDeactivateProyects: false,
    verifyDeactivateProyects: false,
    dynamicContactsFields: null,
    createTradeAgreementPopupFlag: false,
    cancelProcessTradeAgreement: false,
    sucessDeleteFile: 0,
    infoCurrency: null,
    messageActivateClient: false,
    stateClient: false,
    clientIdProyect: null,
    arrayPropertiesCompanyGeneral: [
        'Notifications',
        'GeneralInfo',
        'CompanyInformation',
        'StyleCompany',
        'WorkingDays',
        //system
        'Language',
        'DailyWorkedHours',
        'DailyBilledHours',
        'TypeRestrictionActivityTime',
        'EndDayDeliveryTime',
        'TypeBillableTime',
        'InitialWorkHour',
        'DailyHourRegistrationTimeLimit',
        'HoursTimeAlertActivitiesRevision',
        'FirstEndHour',
        'SecondEndHour',
        'IncludeProcesses',
        'ChanceRange',
        'Holidays',
        'IsWorkDay',
        'Capacity',
        'Count',
        'ContactEmail',
        'Fields',
    ],
    arrayPropertiesCompanySystem: [
        'Notifications',
        'GeneralInfo',
        'CompanyInformation',
        'StyleCompany',
        //general
        'Name',
        'IsMain',
        'Phone',
        'Address',
        'Country',
        'City',
        'ContactEmail',
        'UniqueIdentificationNumber',
        'TimeZone',
        'Holidays',
        'Active',
        'Logo',
        'IsWorkDay',
        'ChanceRange',
    ],
    emailExterns: [],
    arrayPropertiesFunctionalStructurePractice: ['Id', 'Capacity', 'SubAreas'],
    arrayPropertiesFunctionalStructureUnitServices: ['Id', 'Capacity'],
    practiceHistoryId: null,
    serviceUnitHistoryId: null,
    arrayPropertiesCompanyCurrency: [
        'Type',
        'Standard',
        'Symbol',
        'Description',
        'CompanyId',
    ],
    arrayPropertiesCompanyIndexAndUnit: [],
    HistoryCurrencyIndexId: null,
    projectsByClient: [],
    projectsByContact: [],
    parametersOutlook: {},
    contactIdParam: null,
    IdHistoryRol: null,
    arrayPropertiesRoles: [],
    idHistoryUsers: null,
    arrayPropertiesUsersAdmin: ['NotificationsParam', 'Position_Con', 'Fields'],
    IdHistoryProyect: null,
    arrayPropertiesProyects: [
        'AssignedWorkgroups',
        'ProyectsFiles',
        'CommercialUsers',
        'ExternalMembersGroup',
        'RetiredMembersPrivacy',
        'IntervalDefinition',
        'Id',
        'CreateDate',
        'FinishedDate',
    ],
    arrayPropertiesMembersPrivacy: [
        'ClientName',
        'Name',
        'Description',
        'SubAreas',
        'SolicitedUserContact',
        'ProyectsFiles',
        'IntervalDefinition',
        'Active',
        'Id',
        'CreateDate',
        'FinishedDate',
        'ProyectFields',
        'ClientId',
    ],
    messageInactiveClient: false,
    IdHistoryAgreementCommercial: null,
    arrayPropertiesCommercialAgreementProyect: [
        'ClientName',
        'Name',
        'SubAreas',
        'SubAreas',
        'CommercialUsers',
        'AssignedWorkgroups',
        'ProyectsFiles',
    ],
    IdHistoryWorkGroup: null,
    arrayPropertiesWorkGroup: [],
    userIdReassingProcess: null,
    IdHistoryContact: null,
    arrayPropertiesContact: [],
    IdHistoryClient: null,
    arrayPropertiesClient: ['Fields'],
    IdHistoryTariff: null,
    arrayPropertiesTariffCategory: [],
    showProcessSelectBox: false,
    IdHistoryCitationUpdate: null,
    IdHistoryDynamicForm: null,
    arrayPropertiesDynamicForm: [],
    IdHistoryTypeActivity: null,
    arrayPropertiesTypeActivity: [],
    IdHistoryFlows: null,
    arrayPropertiesFlows: [
        'Title',
        'TypeSend',
        'TypeNotification',
        'DescriptionAlert',
    ],
    IdHistoryAlertsFlows: null,
    arrayPropertiesAlertsFlows: [],
    taxesByCountry: [],
    paramsRates: JSON.parse(sessionStorage.getItem('m_paramsRates')),
    shareActivityToSelectedUsers: 'false',
    editWorkgroup: null,
});

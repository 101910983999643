export const setTitle = (state, title) => {
    state.title = title;
};

export const setSubTitle = (state, subTitle) => {
    state.subTitle = subTitle;
};
export const setGeneralInfotWorkFlowsCreateOrEditObject = (state, data) => {
    state.workFlowsCreateOrEditObjectProd.code = data.code;
    state.workFlowsCreateOrEditObjectProd.id = data.id;
    state.workFlowsCreateOrEditObjectProd.companyId = data.companyId;
    state.workFlowsCreateOrEditObjectProd.name = data.name;
    state.workFlowsCreateOrEditObjectProd.areaId = data.areaId;
    state.workFlowsCreateOrEditObjectProd.areaName = data.areaName;
    state.workFlowsCreateOrEditObjectProd.subAreaName = data.subAreaName;
    state.workFlowsCreateOrEditObjectProd.subAreaId = data.subAreaId;
    state.workFlowsCreateOrEditObjectProd.dynamicForm = data.dynamicForm;
    state.workFlowsCreateOrEditObjectProd.description = data.description;
    state.workFlowsCreateOrEditObjectProd.dynamiceFormName =
        data.dynamiceFormName;
    state.workFlowsCreateOrEditObjectProd.active = data.active;
    state.workFlowsCreateOrEditObjectProd.beginTime = data.beginTime;
};
export const setWorkFlowsCreateOrEditObjectDefinitionProperty = (
    state,
    WorkFlowsCreateOrEditObjectDefinitionProperty,
) => {
    state.workFlowsCreateOrEditObjectProd.definition =
        WorkFlowsCreateOrEditObjectDefinitionProperty;
};
export const setWorkFlowsCreateOrEditObjectActivitiesProperty = (
    state,
    activities,
) => {
    state.workFlowsCreateOrEditObjectProd.activities = activities;
};
export const setGuidBillCreated = (state, guidBillCreated) => {
    state.guidBillCreated = guidBillCreated;
};
export const setGuidOperativeActivityCreated = (
    state,
    setGuidOperativeActivityCreateed,
) => {
    state.operativeActivitie.id = setGuidOperativeActivityCreateed;
};

export const setNotificationsByUser = (state, notifications) => {
    state.notificationsByUser = notifications;
};

export const setViewNotifications = (state, notifications) => {
    state.viewPopUpNotifications = notifications;
};

export const setOperativeWorkflow = (state, operativeWorkflow) => {
    state.operativeWorkflow = operativeWorkflow;
};

export const setOperativeWorkflowId = (state, operativeWorkflowId) => {
    state.operativeWorkflow.id = operativeWorkflowId;
};

export const setJobProduction = (state, jobInfo) => {
    state.jobProduction = jobInfo;
};

export const setOperativeWorkflowGeneralInfo = (
    state,
    operativeWorkflowGeneralInfo,
) => {
    state.operativeWorkflow = operativeWorkflowGeneralInfo;
};
export const setOperativeActivityGeneralInfo = (
    state,
    operativeActivityGeneralInfo,
) => {
    state.operativeActivitie = operativeActivityGeneralInfo;
};

export const setOperativeWorkflowTradeAgreementId = (
    state,
    operativeWorkflowTradeAgreementId,
) => {
    state.operativeWorkflow.tradeAgreementId =
        operativeWorkflowTradeAgreementId;
};

export const setTradeAgreementProduction = (state, data) => {
    state.tradeAgreementProduction.type = data.type;
    state.tradeAgreementProduction.info = data.info;
};

export const setActivitiesOperativeWorkflow = (state, activities) => {
    state.operativeWorkflow.activities = activities;
};

export const setDefinitionOperativeWorkflow = (state, definition) => {
    state.operativeWorkflow.definition = definition;
};

export const setUsersResponsible = (state, usersResponsibleValues) => {
    state.usersResponsibleValues = usersResponsibleValues;
};

export const setDynamicFormOperativeWorkflows = (state, dynamicForm) => {
    state.operativeWorkflow.operativeDynamicForm = dynamicForm;
};

export const setTradeAgreementProductionObject = (
    state,
    tradeAgreementProductionObject,
) => {
    state.tradeAgreementProductionObject = tradeAgreementProductionObject;
};

export const setOperativeActivitiesToCreate = (
    state,
    operativeActivitiesToCreate,
) => {
    state.operativeActivitiesToCreate = operativeActivitiesToCreate;
};

export const setOperativeActivitiesCreatedArray = (
    state,
    operativeActivityCreated,
) => {
    state.operativeActivitiesCreatedArray.push(operativeActivityCreated);
};

export const setIdOperativeWorkflowToProcess = (
    state,
    idOperativeWorkflowToProcess,
) => {
    state.idOperativeWorkflowToProcess = idOperativeWorkflowToProcess;
};
export const setIdOperativeActivityByUserToProcess = (
    state,
    idOperativeActivitieToProcess,
) => {
    state.idOperativeActivitieToProcess = idOperativeActivitieToProcess;
};
export const setOperativeActivityVal = (state, val) => {
    state.OperativeActivityValues = val;
};

export const setOperativeDynamicFields = (state, val) => {
    state.operativeDinamycValues = val;
};

export const updateFilterUserShare = (state, filter) => {
    state.selectedUserShare = filter;
};
export const updateFilterUserShareActivity = (state, filter) => {
    state.chargeUsersShare = filter;
};
export const updateFilterUserResponsable = (state, filter) => {
    state.selectedUserResponsable = filter;
};

export const setConfigFilters = (state, filter) => {
    state.configFilters = filter;
};

export const updateFilterUserShareCount = (state, filter) => {
    state.selectedUserShareCount = filter;
};

export const setOperativeDinamycForm = (state, operativeDinamycForm) => {
    state.operativeDinamycForm = operativeDinamycForm;
};

export const setOperativeDinamycFormWithAnswers = (
    state,
    operativeDinamycFormWithAnswers,
) => {
    state.operativeDinamycFormWithAnswers = operativeDinamycFormWithAnswers;
};

export const setExpensesActivity = (state, expensesActivity) => {
    state.expensesActivity = expensesActivity;
};

export const setExpense = (state, expense) => {
    state.expenseFormState = expense;
};

export const setOperativeWorks = (state, operativeWorks) => {
    state.operativeWorks = operativeWorks;
};

export const setEntityIdForWorks = (state, entityId) => {
    state.entityId = entityId;
};
export const setCaseVal = (state, currentCase) => {
    state.currentCase = currentCase;
};
export const setlistDefendants = (state, data) => {
    state.listDefendants = data;
};
export const setlistPlaintiffs = (state, data) => {
    state.listPlaintiffs = data;
};

export const setWorksByAuth = (state, worksByAuth) => {
    state.worksByAuth = worksByAuth;
};

export const setReloadGridExpenses = (state, reload) => {
    state.reloadGridExpenses = reload;
};

export const setNameGeneralInfo = (state, name) => {
    state.nameGeneralInfo = name;
};
export const setReadyDiagram = (state, val) => {
    state.readyDiagram = val;
};
export const setIdActivityToShowOnSidetab = (
    state,
    idActivityToShowOnSidetab,
) => {
    state.idActivityToShowOnSidetab = idActivityToShowOnSidetab;
};

export const setValidateSaveOrFinish = (state, val) => {
    state.validateSaveOrFinish = val;
};
export const setHaveResponsable = (state, val) => {
    state.haveResponsable = val;
};

export const setWorkflowInfo = (state, workflowToSidetab) => {
    state.workflowToSidetab = workflowToSidetab;
};

export const setShowShareTabOnActivity = (state, showShareTabOnActivity) => {
    state.showShareTabOnActivity = showShareTabOnActivity;
};

export const setDisabledCheckTradeAgreement = (
    state,
    disabledCheckTradeAgreement,
) => {
    state.disabledCheckTradeAgreement = disabledCheckTradeAgreement;
};

export const setParamsWorkflow = (state, paramsWorkflow) => {
    sessionStorage.setItem('m_paramsWF', JSON.stringify(paramsWorkflow));
    state.paramsWorkflow = paramsWorkflow;
};

export const changeStateActivity = (state, stateActivity) => {
    state.OperativeActivityValues.state = stateActivity;
};
export const setWorkFlowIsClose = (state, isclose) => {
    state.workFlowIsClose = isclose;
};
export const setShareActivity = (state, value) => {
    state.shareActivityToSelectedUsers = value + Math.random();
};
export const setWorkFlowInProgress = (state, isprogress) => {
    state.workFlowInProgress = isprogress;
};

export const setUpdateActivityByTask = (state, newState) => {
    state.updateActivityByTask = newState;
};
export const setTradeAgreementByOptWorkFlow = (state, idTradeAgreement) => {
    state.idTradeAgreementOptWf = idTradeAgreement;
};

export const setOperativeDynamicIdChanges = (state, idDynamicFormOptWf) => {
    state.idDynamicFormOptWf = idDynamicFormOptWf;
};
export const setlocalStateOportunity = (state, newState) => {
    state.localStateOportunity = newState;
};

export const setInfoLitigiosData = (state, newDataRecibed) => {
    state.infoLitigiosData = newDataRecibed;
};

export const setShowItemsHeaderReports = (state, newDataRecibed) => {
    state.showItems = newDataRecibed;
};

export const setSubAreasByProjects = (state, subAreasByProjects) => {
    state.subAreasByProjects = subAreasByProjects;
};

export const updateFilterUserResponsableWorkFlow = (state, filter) => {
    state.selectedUserResponsableWorkflow = filter;
};

export const setOperativeWorkflowResponsable = (state, userResponsable) => {
    state.operativeWorkflow.groupResponsable = userResponsable;
};

export const setActivitySaved = (state, activitySaved) => {
    state.activitySaved = activitySaved;
};

export const setEliminatedFlow = (state, eliminatedFlow) => {
    state.eliminatedFlow = eliminatedFlow;
};

export const setHabilityPopupState = (state, habilityPopupState) => {
    state.habilityPopupState = habilityPopupState;
};
export const setGridReportDef = (state, definitionRet) => {
    state.gridReport = definitionRet;
};
export const setNameReport = (state, definitionRet) => {
    state.titleReport = definitionRet;
};
export const updateShowReopen = (state, show) => {
    state.showReopen = show;
};
export const setIsCyclicActivity = (state, isCiclyc) => {
    state.isCyclicActivity = isCiclyc;
};
export const setIsCyclicActivityRepeat = (state, isCiclycRepeat) => {
    state.isCyclicActivityRepeat = isCiclycRepeat;
};

export const setRestartDynamicFormComponent = (
    state,
    restartDynamicFormComponent,
) => {
    state.restartDynamicFormComponent = restartDynamicFormComponent;
};

export const setIsActivityComingFromFlow = (
    state,
    isActivityComingFromFlow,
) => {
    state.isActivityComingFromFlow = isActivityComingFromFlow;
};

export const activitiesForFlow = (state, activities) => {
    state.activitiesForFlow = activities;
};

export const setIsEndDateTradeAgreementValidation = async (
    state,
    isEndDateTradeAgreementValidation,
) => {
    state.isEndDateTradeAgreementValidation = isEndDateTradeAgreementValidation;
};
export const setPartnerGrid = async (state, partnerGrid) => {
    state.partnerGrid = partnerGrid;
};
export const setFromDashClients = async (state, fromDashClients) => {
    state.fromDashClients = fromDashClients;
};
export const setIsEndDateTradeAgreementValidationNumber = async (
    state,
    isEndDateTradeAgreementValidationNumber,
) => {
    state.isEndDateTradeAgreementValidationNumber =
        isEndDateTradeAgreementValidationNumber;
};

export const setIsHabilityBtnHistoryCitation = (
    state,
    isHabilityBtnHistoryCitation,
) => {
    state.isHabilityBtnHistoryCitation = isHabilityBtnHistoryCitation;
};

export const setIsClearFilterTransversal = (
    state,
    isClearFilterTransversal,
) => {
    state.isClearFilterTransversal = isClearFilterTransversal;
};

export const setClearFilterTransversalColumnOne = (
    state,
    clearFilterTransversalColumnOne,
) => {
    state.clearFilterTransversalColumnOne = clearFilterTransversalColumnOne;
};

export const setClearFilterTransversalColumnTwo = (
    state,
    clearFilterTransversalColumnTwo,
) => {
    state.clearFilterTransversalColumnTwo = clearFilterTransversalColumnTwo;
};

export const setCantCloseWorkflow = (
    state,
    cantCloseWorkflow,
) => {
    state.cantCloseWorkflow = cantCloseWorkflow;
};

import managementApi from '@/api/managementApi';
import filesApi from '@/api/filesApi';
import settlementApi from '@/api/settlementApi';
import organizationApi from '@/api/organizationApi';
import typeActivitySchema from '@/utils/models/typeActivitySchema';
import roleSchema from '@/utils/models/roleSchema';
import companySchema from '@/utils/models/companySchema';
import productionApi from '@/api/productionApi';
import clientSchema from '@/utils/models/clientSchema';
import { setGeneralInfotWorkFlowsCreateOrEditObjectBeginTimeProperty } from './mutations';
import store from '@/store/index';

export const createClient = async ({ commit }, setData) => {
    const { data } = await managementApi.post(`Clients`, setData);
    commit('resultPut', data);
    return data.data;
};

export const getClient = async ({ commit }, id) => {
    const { data } = await managementApi.get(`Clients/${id}`);
    return data.data;
};

export const getEmpresarialGroups = async ({ commit }) => {
    const { data } = await managementApi.get(`EnterpriseGroups`);
    commit('setEnterpriseGroups', data.data);
};
export const setEmpresarialGroups = async ({ commit }, newdata) => {
    //const { data } =
    await managementApi.post(`EnterpriseGroups`, newdata);
    //commit('setEnterpriseGroups', data.data);
};
export const deleteEmpresarialGroups = async ({ commit }, item) => {
    //const { data } =
    await managementApi.delete(`EnterpriseGroups`, { data: item });
    //commit('setEnterpriseGroups', data.data);
};

export const updateEmpresarialGroups = async ({ commit }, editdata) => {
    //const { data } =
    await managementApi.put(`EnterpriseGroups`, editdata);
    //commit('setEnterpriseGroups', data.data);
};

export const updateActivitiesProject = async ({ commit }, dataToSend) => {
    await managementApi.put(`Proyects/UpdateActivitiesByProyect`, dataToSend);
};

export const getEmpresarialGroupsByCompany = async (
    { commit },
    guidComapny,
) => {
    const response = await managementApi
        .get(`EnterpriseGroups/GetECI/${guidComapny}`)
        .then((response) => {
            if (response.status === 200) {
                commit('setEnterpriseGroups', response.data.data);
                return response.data.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            return { success: false, codError: 1, data: null };
        });
    return response.data;
};

export const getUsersByCompany = async ({ commit }, guidCompany) => {
    const response = await managementApi
        .get(`User/UsersByCompanyId/${guidCompany}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            return { success: false, codError: 1, data: null };
        });
    response.data = response.data.filter(
        (object) => object.iscontact !== true && object.active === true,
    );
    return response;
};

export const getShareActivityUsersByCompanyId = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `User/ShareActivityUsersByCompanyId/${id}`,
    );
    commit('setUsersShareList', data.data);
    return data.data;
};

export const getActivitiesByCompanyId = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Activities/GetActivitiesByCompany/${id}`,
    );
    commit('setActivities', data.data);
    return data.data;
};

export const getCompanies = async ({ commit }) => {
    const { data } = await managementApi.get(`Companies`);
    data.data.forEach((company) => {
        company.email = company.generalInfo.contactEmail[0];
    });
    commit('setCompanies', data.data);
};

export const getSubthemesByActivity = async ({ commit }, id) => {
    const response = await managementApi
        .get(`Activities/GetSubthemes/${id}`)
        .then((response) => {
            if (response.status === 200) {
                commit('setSubthemes', response.data.data);
                return response.data.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            return { success: false, codError: 1, data: null };
        });
    return response.data;
};

export const getCompanyById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`Companies/${id}`);
    commit('setSeletedCompany', data.data);
    return data.data;
};

export const getCategories = async ({ commit }) => {
    const { data } = await managementApi.get(`Categories`);
    commit('setCategories', data.data);
};

export const getFlowsByCompanyId = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Workflows/GetWorkflowsByCompany/${id}`,
    );
    commit('setFlows', data.data);
    return data.data;
};

export const getAlerts = async ({ commit }) => {
    // const { data } = await managementApi.get(`Workflows`);
    commit('setAlerts', []);
};

export const getBillsbyId = async ({ commit }, id) => {
    const { data } = await productionApi.get(
        `Expenses/GetOperativeExpensesByProyectId/${id}`,
    );
    let setData = data.data;
    setData.forEach((data) => {
        data.filesLength = `${
            setData.file?.length === 1
                ? '1'
                : setData.file?.length > 1
                ? `+${setData.file?.length}`
                : '0'
        }`;
    });
    commit('setBills', setData);
    return setData;
};

export const getProjectActivities = async ({ commit }, idProject) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/SearchActivitiesbyProyectid/${idProject}`,
    );
    commit('setProjectActivities', data.data);
};

export const getContacts = async ({ commit }, isContact) => {
    const infoApi = {
        startDate: new Date('2021-01-01'),
        endDate: new Date('2021-01-01'),
        usersIdArray: [],
        isContact: isContact,
    };
    const { data } = await managementApi.post(`User/GetContacts`, infoApi);
    let setData = data.data;
    await setData.forEach((object) => {
        object.nameTypeContact = `${
            object.type_Con === 1
                ? 'Facturación'
                : object.type === 2
                ? 'Producción'
                : object.type === 3
                ? 'Comercial'
                : 'No es contacto'
        }`;
    });
    commit('setContacts', data.data);
};

export const getContactById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`User/${id}`);
    commit('setContactForm', data.data);
};

// export const getContactsByClient = async ({ commit }, idClient) => {
//     const infoApi = {
//         idClient,
//     };
//     const { data } = await managementApi.post(`User/GetContacts`, infoApi);
//     commit('setContacts', data.data);
// };

export const getContactsByClient = async ({ commit }, idClient) => {
    if (idClient != null) {
        const { data } = await managementApi.get(
            `Clients/GetContactsByClient/${idClient}`,
        );
        // data.data.forEach((contact) => {
        //     contact.name = `${contact.name} ${contact.lastName}`;
        // });
        commit('setContactsByClient', data.data);
    } else {
        commit('setContactsByClient', []);
    }
};

export const getClientsByContact = async ({ commit }, idContact) => {
    if (idContact != null) {
        const { data } = await managementApi.get(
            `Clients/GetClientsByContact/${idContact}`,
        );
        commit('setClientsByContact', data.data);
        return data.data;
    } else {
        commit('setClientsByContact', []);
    }
};

export const put = async ({ commit, rootState }, config) => {
    const { api, data, continueDeactivate, isCancel } = config;
    let tempData;
    if (api === 'Clients') {
        let country = rootState.global.company.countries.find(
            (country) => country.nameCountry === data.country,
        );
        data.country = country?.idCountry;
        let city = country?.citys.find(
            (city) => city.nameCity === data.mainCity,
        );
        data.mainCity = city?.idCity;
        if (data.country == null || data.country == undefined) {
            data.country = 'COL';
        }
        if (data.mainCity == null || data.mainCity == undefined) {
            data.mainCity = '1170483426';
        }
    }
    if (api === 'Companies') {
        let country = rootState.global.company.countries.find(
            (country) => country.nameCountry === data.generalInfo.country,
        );
        data.generalInfo.country = country?.idCountry;
        let city = country?.citys.find(
            (city) => city.nameCity === data.generalInfo.city,
        );
        data.generalInfo.city = city?.idCity;
    }
    if (api === 'User') {
        tempData = { ...data };
        tempData.associatedCompany = data.associatedCompanyId;
        tempData.category = data.categoryNom;
        tempData.Country_Con = data.country_ConNom;
        tempData.City_Con = data.city_ConNom;
    }
    if (api === 'WorkGroup') {
        data.members = data.membersObject;
    }
    if (api === 'CurrencyAndIndexingUnit') {
        let newdata = {
            ...data,
            userId: store.state.auth.userDB.userId ?? null,
        };
        const response = await settlementApi.put(`${api}`, newdata);
        return response;
    } else if (api === 'Process') {
        const response = await productionApi.put(`${api}`, data);
        return response;
    } else {
        if (!continueDeactivate) {
            let request = {
                ...data,
                verifyDeactivation: true,
            };

            if (api == 'User')
                request = {
                    ...tempData,
                    verifyDeactivation: true,
                };

            const response = await managementApi.put(`${api}`, request);

            if (api === 'Clients' && response.status === 200) {
                if (response.data?.data?.showModalConfirmDeactivate) {
                    commit('setConfirmDeactivateProyectsClients', true);
                } else {
                    commit('setConfirmDeactivateProyectsClients', false);

                    await settlementApi.post(
                        'Billing/UpdateBlocksTradeAgreements',
                        response.data?.data,
                    );
                    const responsePROD = await productionApi.post(
                        'OperativeWorkFlows/DeactivateWorkflowsGeneric',
                        response.data?.data,
                    );
                    if (responsePROD.status === 200) {
                        const response2 = await settlementApi.post(
                            'Billing/UpdateBlocksTradeAgreements',
                            responsePROD.data?.data,
                        );
                        commit('setStateDeactivateClient', true);
                        commit('setVerifyDeactivateClients', false);
                    }
                }
            } else {
                commit('setStateDeactivateClient', false);
                commit('setVerifyDeactivateClients', false);
            }
            if (api == 'DynamicForms' || api == 'Activities') {
                return response;
            }
            if (api == 'Roles' || api == 'User/PutContact') {
                return response;
            }
        } else {
            let request2 = {
                ...data,
                verifyDeactivation: false,
            };

            const response = await managementApi.put('Clients', request2);

            if (response.status === 200) {
                await settlementApi.post(
                    'Billing/UpdateBlocksTradeAgreements',
                    response.data?.data,
                );
                const responsePROD = await productionApi.post(
                    'OperativeWorkFlows/DeactivateWorkflowsGeneric',
                    response.data?.data,
                );
                if (responsePROD.status === 200) {
                    const response2 = await settlementApi.post(
                        'Billing/UpdateBlocksTradeAgreements',
                        responsePROD.data?.data,
                    );
                    commit('setStateDeactivateClient', true);
                    commit('setVerifyDeactivateClients', false);
                }
            } else {
                commit('setStateDeactivateClient', false);
                commit('setVerifyDeactivateClients', false);
            }
        }
    }
};

export const putRole = async ({ commit }, config) => {
    const { api, role, id, companyInfo } = config;
    const sendData = {
        ...roleSchema,
        ...role,
        id: id,
        idCompany: companyInfo,
    };
    const { data } = await managementApi.put(`${api}`, sendData);
    commit('resultPut', data);
};

export const putActivity = async ({ commit }, config) => {
    const { api, activity, areas } = config;
    if (areas) {
        areas.filter((area) => {
            if (area.id === activity.areaId) {
                activity.nameArea = area.name;
                area.subAreas.filter((subArea) => {
                    if (subArea.id === activity.subAreaId) {
                        activity.nameSubArea = subArea.name;
                    }
                });
            }
        });
    }
    const sendData = {
        ...typeActivitySchema,
        ...activity,
    };
    commit('updateActivity', sendData);
    const { data } = await managementApi.put(`${api}`, sendData);
    commit('resultPut', data);
    return data;
};

export const actionDelete = async ({ commit }, config) => {
    const { api, id } = config;
    await managementApi.put(`${api}`, id);
};

export const getGrid = async ({ commit }, configObject) => {
    const { data } = await organizationApi.post(
        `GridDefinition/SearchGridDefinition`,
        configObject,
    );
    commit('setGrid', data.data[0]);
};

export const getGrid2 = async ({ commit }, configObject) => {
    const { data } = await organizationApi.post(
        `GridDefinition/SearchGridDefinition`,
        configObject,
    );
    commit('setGrid2', data.data[0]);
};

export const createTypeActivity = async ({ commit }, configObject) => {
    const { activity, areas, companyId } = configObject;
    activity.companyId = companyId;
    areas.filter((area) => {
        if (area.id === activity.areaId) {
            activity.nameArea = area.name;
            area.subAreas.filter((subArea) => {
                if (subArea.id === activity.subAreaId) {
                    activity.nameSubArea = subArea.name;
                }
            });
        }
    });
    const sendData = {
        ...typeActivitySchema,
        ...activity,
    };
    const { data } = await managementApi.post(`Activities`, sendData);
    commit('updateActivities', data.data[0]);
    commit('resultPut', data);
    return data.data;
};

export const createRole = async ({ commit }, configObject) => {
    const { role, companyInfo } = configObject;
    const sendData = {
        ...roleSchema,
        ...role,
        idCompany: companyInfo,
    };
    const { data } = await managementApi.post(`Roles`, sendData);
    commit('resultPut', data);
};

export const getAreas = async ({ commit }, companyIdParam) => {
    const { data } = await managementApi.get(
        `Areas/GetAreasByCompanyId/${companyIdParam}`,
    );
    commit('setAreas', data.data);
};
export const getAreaById = async ({ commit }, areaId) => {
    const { data } = await managementApi.get(`Areas/${areaId}`);
    commit('setAreaById', data.data);
};

export const getWorkflows = async ({ commit }) => {
    const { data } = await managementApi.get(`Workflows`);
    commit('setWorkflows', data.data);
};

export const getCustomersFull = async ({ commit, rootState }, id) => {
    const { data } = await managementApi.post(
        `Clients/GetByCompanyIdFull/${id}`,
    );
    // data.data.forEach((customer) => {
    //     rootState.global.company?.countries?.find((country) => {
    //         if (country.idCountry === customer.country) {
    //             customer.countryNom = country.nameCountry;
    //             country.citys.find((city) => {
    //                 if (city.idCity === customer.mainCity) {
    //                     customer.mainCityNom = city.nameCity;
    //                 }
    //             });
    //         }
    //     });
    // });
    // data.data =
    commit('setCustomers', data.data);
    return data.data;
};
export const getCustomersDataPromise = async ({ commit }, id) => {
    return managementApi.post(`Clients/GetByCompanyId/${id}`);
};
export const getCustomers = async ({ commit, rootState }, id) => {
    const { data } = await managementApi.post(`Clients/GetByCompanyId/${id}`);
    // data.data.forEach((customer) => {
    //     rootState.global.company?.countries?.find((country) => {
    //         if (country.idCountry === customer.country) {
    //             customer.countryNom = country.nameCountry;
    //             country.citys.find((city) => {
    //                 if (city.idCity === customer.mainCity) {
    //                     customer.mainCityNom = city.nameCity;
    //                 }
    //             });
    //         }
    //     });
    // });
    // data.data =
    commit('setCustomers', data.data);
    return data.data;
};
export const getCustomerById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`Clients/${id}`);
    commit('setCustomerForm', data.data);
};

export const getProyectsByAssociateCompany = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Proyects/GetByAssociateCompany/${id}`,
    );
    commit('setProjects', data.data);
};

export const getProjectsById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`Proyects/${id}`);
    return data.data;
};

export const getProjectsByIdPromise = async ({ commit }, id) => {
    return managementApi.get(`Proyects/${id}`);
};

export const createProjects = async ({ commit }, sendToData) => {
    const { data } = await managementApi.post(`Proyects`, sendToData);
    return data.data;
};

export const getUsers = async ({ commit }) => {
    const { data } = await managementApi.get(`User`);
    commit('setUsers', data.data);
};

export const updateListNotifications = async ({ commit }, sendToData) => {
    const { data } = await managementApi.put(
        `Companies/listNotifications`,
        sendToData,
    );
    return data.data;
};
export const updateNotificationsTrade = async ({ commit }, sendToData) => {
    const { data } = await managementApi.put(
        `TradeAgreement/listTrade`,
        sendToData,
    );
    return data.data;
};

export const getUsersByCompanyId = async ({ commit }, id) => {
    const { data } = await managementApi.get(`User/GetUsersByCompany/${id}`);
    commit('setUsers', data.data);
    return data.data;
};

export const getUserById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`User/${id}`);
    commit('setUserForm', data.data);
    return data.data;
};

export const getWorkGroupsByCompanyId = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `WorkGroup/GetWorkgroupsByCompanyId/${id}`,
    );
    commit('setWorkGroup', data.data);
    return data.data;
};

export const getTradeAgreementEntitieByProjectId = async (
    { commit },
    projectId,
) => {
    const { data } = await managementApi.post(
        `Proyects/GetTradeAgreementEntitieByProjectId/${projectId}`,
    );
    commit('setTradeAgreementEntitie', data.data);
};

export const getNotificationsByTrade = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.get(
        `TradeAgreement/GetNotificationsByTrade/${dataToSend.type}/${dataToSend.id}`,
    );
    commit('setNotificationsByTradeAgreement', data.data);
    return data.data;
};

export const getRoles = async ({ commit }, sendData) => {
    const { id, isActive } = sendData;
    const { data } = await managementApi.get(
        `Roles/GetRolesByCompany/${id}/${isActive}`,
    );
    commit('setRoles', data.data);
};

export const createTradeAgreement = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(`TradeAgreement`, dataToSend);
    commit('setTradeAgreementId', data.data);
    return data.data;
};

export const getTradeAgreements = async ({ commit }) => {
    const { data } = await managementApi.get(`TradeAgreement`);
    commit('setTradeAgreements', data.data);
};

export const getActvititesTypeByAreaIdAndSubAreaIdAndState = async (
    { commit },
    info,
) => {
    const { data } = await managementApi.post(
        `Activities/GetActvititesTypeByAreaIdAndSubAreaIdAndState`,
        info,
    );
    commit('setActvititesTypeByAreaIdAndSubAreaIdAndState', data.data);
    return data.data;
};

export const createWorkFlow = async ({ commit }, info) => {
    info.description = info.description ?? '';
    const { data } = await managementApi.post(`Workflows`, info);
    commit('resultPut', data);
};

export const updateWorkflow = async ({ commit }, info) => {
    const { data } = await managementApi.put(`Workflows`, info);
    commit('resultPut', data);
};

export const createDynamicForm = async ({ commit }, dynamicForm) => {
    const { data } = await managementApi.post(`DynamicForms`, dynamicForm);
    commit('resultPut', data);
    return data.data;
};

export const putDynamicForm = async ({ commit }, dynamicForm) => {
    const { data } = await managementApi.put(`DynamicForms`, dynamicForm);
    commit('resultPut', data);
    return data.data;
};

export const getDynamicFormById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`DynamicForms/${id}`);
    if (data.data !== null) {
        commit('setDynamicForm', data.data);
    }
    return data.data;
};

export const getFiledByIds = async ({ commit }, ids) => {
    const { data } = await managementApi.post(`Field/fieldsbyArrayIds`, ids);
    return data.data;
};

export const getDynamicFormsByCompanyId = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `DynamicForms/GetDynamicByCompany/${id}`,
    );
    let setData = data.data;
    if (data.data !== null) {
        setData.forEach((data) => {
            data.infoWorkFlowsNames = [];
            if (data.infoWorkflows !== null) {
                data.infoWorkflows.forEach((workFlow) => {
                    data.infoWorkFlowsNames.push(workFlow.name);
                });
            }
        });
    }
    commit('setDynamicFormsToWorkflows', data.data);
    return data.data;
};

export const getWorkflowById = async ({ commit }, { id, callFrom }) => {
    const { data } = await managementApi.get(`Workflows/${id}`);
    if (callFrom === 2) {
        commit(
            'production/setGeneralInfotWorkFlowsCreateOrEditObject',
            data.data,
            { root: true },
        );
        commit(
            'production/setWorkFlowsCreateOrEditObjectDefinitionProperty',
            data.data.definition,
            { root: true },
        );
        commit(
            'production/setWorkFlowsCreateOrEditObjectActivitiesProperty',
            data.data.activities,
            { root: true },
        );
    } else {
        commit('setGeneralInfotWorkFlowsCreateOrEditObject', data.data);
        commit(
            'setWorkFlowsCreateOrEditObjectDefinitionProperty',
            data.data.definition,
        );
        commit(
            'setWorkFlowsCreateOrEditObjectActivitiesProperty',
            data.data.activities,
        );
    }
};
export const getWorkflowDataById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`Workflows/${id}`);
    commit('setTotalObjectWorkFlow', data.data);
    commit('setNotificationsFlow', data.data);
};
export const getWorkGroupById = async ({ commit }, id) => {
    const { data } = await managementApi.get(`WorkGroup/${id}`);
    commit('setOrgChartCreateOrEditObject', data.data);
    commit('setOrgChartCreateOrEditObjectMembersProperty', data.data.members);
    commit('updateApoyoOnlyItems', data.data.externalUsers);
    commit(
        'setOrgChartCreateOrEditObjectDefinitionProperty',
        data.data.definition,
    );
};

export const getUsersToWorkGroups = async ({ commit }, id) => {
    const { data } = await managementApi.get(`User/GetUsersForWorgroups/${id}`);
    commit('setUsersToOrgChart', data.data);
};

export const createWorkgroup = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(`WorkGroup`, dataToSend);
    commit('resultPut', data);
};

export const updateWorkgroup = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.put(`WorkGroup`, dataToSend);
    commit('resultPut', data);
};

export const getDynamicFieldsByForm = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `SystemForms/GetDynamicFieldsByForm`,
        dataToSend,
    );
    if(data){
        data.data.fields.forEach(field => {
            if(field.options.length > 0){
                field.options.forEach(opt => {
                    if (opt.label.includes('\n') || opt.value.includes('\n')) {
                        opt.label = opt.label.replace('\n', '');
                        opt.value = opt.value.replace('\n', '');
                    }
                });
            }
        });
    }
    commit('setDynamicFieldsByForm', data.data);
    return data.data;
};

export const getDynamicFieldsByFormPromise = async ({ commit }, dataToSend) => {
    return managementApi.post(`SystemForms/GetDynamicFieldsByForm`, dataToSend);
};

export const getDynamicFormWithFieldsById = async ({ commit }, { id }) => {
    const { data } = await managementApi.get(
        `DynamicForms/GetDynamicFormWithFieldsById/${id}`,
    );
    if (data.data !== null) {
        commit('setDynamicForm', data.data);
    }
};

export const getTypeExpensesByCompanyId = async (
    { commit },
    interCompanyId,
) => {
    const { data } = await managementApi.get(
        `Expenses/GetTypeExpensesByCompanyId/${interCompanyId}`,
    );
    commit('setTypeExpenses', data.data);
};

export const getProjectsWithNameClientByAssociateCompanyId = async (
    { commit },
    dataSend,
) => {
    const { data } = await managementApi.post(
        `Proyects/GetProyectsAndClientByAssociateCompanyId`,dataSend
    );
    commit('setProjectsWithNameClient', data.data);
    return data.data;
};
export const getProjectsWithNameClientByAssociateCompanyIdForEdit = async (
    { commit },
    dataSend,
) => {
    const { data } = await managementApi.post(
        `Proyects/GetProyectsAndClientByAssociateCompanyIdForEdit`,dataSend
    );
    commit('setProjectsWithNameClient', data.data);
    return data.data;
};
export const getProyectsAndClientByAssociateCompanyIdForCreated = async (
    { commit },
    dataSend,
) => {
    const { idCompany, location } = dataSend;
    const { data } = await managementApi.get(
        `Proyects/GetProyectsAndClientByAssociateCompanyIdForCreated/${idCompany}/${location}`,
    );
    commit('setProjectsWithNameClient', data.data);
    return data.data;
};

export const getTypeActivityById = async ({ commit }, activityId) => {
    const { data } = await managementApi.get(`Activities/${activityId}`);
    commit('setTypeActivity', data.data);
    return data.data;
};

export const getPossibleUsersAndTime = async ({ commit }, possibleUsers) => {
    commit('setUpdateStateLoading', true);
    const { data } = await managementApi.post(
        `User/PossibleUsersAndTime`,
        possibleUsers,
    );
    commit('setPossibleUsersAndTime', data.data);
    commit('setUpdateStateLoading', false);
    return data.data;
};

export const getActivityAreaAndSubAreaByAssociateProyectId = async (
    { commit },
    dataToSend,
) => {
    const { data } = await managementApi.post(
        `Proyects/GetActivityAreaAndSubAreaByAssociateProyectId`,
        dataToSend,
    );
    commit('setActivitySubAreaByProject', data.data);
    return data.data;
};
export const getWorkflowsWithAresaAndSubAreasByAssociateCompanyId = async (
    { commit },
    associateCompanyId,
) => {
    const { data } = await managementApi.get(
        `Workflows/GetWorkflowsWithAreaAndSubAreaByCompanyId/${associateCompanyId}`,
    );
    commit('setWorkflowsWithAresaAndSubAreas', data.data);
};

export const getWorkGroupsByCompanyIdForDropdown = async (
    { commit },
    associateCompanyId,
) => {
    const { data } = await managementApi.get(
        `WorkGroup/GetWorkgroupsByCompanyId/${associateCompanyId}`,
    );
    commit('setWorkGroupsForDropdown', data.data);
    return data.data;
};

export const getWorkGroupsByCompanyIdForDropdownCreate = async (
    { commit },
    config,
) => {
    const { data } = await managementApi.get(
        `WorkGroup/GetWorkgroupsByCompanyIdCreate/${config.associateCompanyId}/${config.location}/${config.create}`,
    );
    commit('setWorkGroupsForDropdown', data.data);
    return data.data;
};

export const getUsersByWorkGroupsForDropdown = async (
    { commit },
    searchUsersData,
) => {
    const { data } = await managementApi.post(
        `User/GetInfoUsersForResponsableDropdown`,
        searchUsersData,
    );
    commit('setUsersByWorkGroupsForDropdown', data.data);
};
export const putCompany = async ({ commit }, config) => {
    const { api, company, id } = config;
    if (typeof company.generalInfo.contactEmail === 'string') {
        company.generalInfo.contactEmail = [company.generalInfo.contactEmail];
    }
    const sendData = {
        ...companySchema,
        id,
        ...company,
    };
    const { data } = await managementApi.put(`${api}`, sendData);
    commit('resultPut', data);
};
export const getProcessWithNameAndIdForDropdown = async (
    { commit },
    clientId,
) => {
    const { data } = await productionApi.get(
        `Process/GetProcessByClientIdForDropdown/${clientId}`,
    );
    commit('setProcessWithNameAndIdForDropdown', data.data);
};

export const getWorkGroupsByUser = async ({ commit }, info) => {
    const { data } = await managementApi.post(
        'WorkGroup/GetUsersInGroupsByUserIdArray',
        info,
    );
    commit('setWorkGroupsByUser', data.data);
};

export const getPermissionsByModule = async ({ commit }) => {
    const { data } = await managementApi.get('Modules');
    commit('setPermissionsByModule', data.data);
};

export const getAreasBySubareasId = async ({ commit }, ids) => {
    const { data } = await managementApi.post(
        'Areas/GetAreasBySubareasId',
        ids,
    );
    return data.data;
};

export const getAreasBySubareasIdPromise = async ({ commit }, ids) => {
    return managementApi.post('Areas/GetAreasBySubareasId', ids);
};

export const getAreasTreeBySubareasId = async ({ commit }, ids) => {
    const { data } = await managementApi.get(
        `Areas/GetAreasTreeByCompanyId/${ids}`,
    );
    commit('setFullAreasUsuario', data.data);
};

export const getAreasTreeBySubareasIdPromise = async ({ commit }, ids) => {
    return managementApi.get(`Areas/GetAreasTreeByCompanyId/${ids}`);
};

export const getUsersByFather = async ({ commit }, id) => {
    const { data } = await managementApi.get(`User/GetUsersByFather/${id}`);
    return data.data;
};
export const getContactsByClientT = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Clients/GetContactsByClient/${id}`,
    );
    commit('setContactByClient', data.data);
    return data.data;
};

export const uploadFileS3 = async ({ commit }, file) => {};

export const getUserByWorkgroup = async ({ commit }, ids) => {
    const { data } = await managementApi.post(
        'WorkGroup/GetUsersByGroups',
        ids,
    );
    const setData = data.data.flatMap((object) =>
        object.users.map((user) => user.id),
    );
    const filterData = [...new Set(setData)];
    return filterData;
};

export const createNewField = async ({ commit }, field) => {
    const { data } = await managementApi.post('Field', field);
    return data.data;
};

export const updateProjects = async ({ commit }, sendToData) => {
    const { data } = await managementApi.put(`Proyects`, sendToData);
    commit('resultPut', data);
    return data.data;
};
export const putNewField = async ({ commit }, field) => {
    const { data } = await managementApi.put('Field', field);
    // commit('setDynamicForm', { fields: data.data });
    return data.data;
};

export const getHistoryById = async ({ commit }, id) => {
    const { data } = await managementApi.post(
        'ChangesHistory/SearchHistoryByItemId',
        id,
    );
    commit('setHistoryById', data.data);
    return data.data;
};

export const getTypeNotifications = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Companies/getNotificationsCompany/${id}`,
    );
    commit('setTypenotifications', data.data);
    return data.data;
};

export const GetUsersMentions = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `User/GetUsersByAssignedTask/${id}`,
    );
    commit('setUserMentions', data.data);
};

export const getJobByUserManagement = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Proyects/GetJobAsignedByUser/${id}`,
    );
    commit('setJobManagement', data.data);
    return data.data;
};

export const getUsersByInfoUserParam = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `User/GetUsersByInfoUserParam/${id}`,
    );
    commit('setUsersReasign', data.data);
    return data.data;
};

export const getInfoUserWithCategory = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `User/GetUsersWithCategory`,
        dataToSend,
    );
    return data.data;
};
export const getActivities = async ({ commit }) => {
    const { data } = await managementApi.get(`Activities`);
    commit('setActivities', data.data);
    return data.data;
};

export const getTradeAgreementsByProyectIdCopy = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Proyects/GetTradeAgreementsByProyectId/${id}`,
    );
    const setData = [...data.data];
    setData.id = null;
    await setData.forEach((object) => {
        object.nameType = `${
            object.type === 1
                ? 'Por hora'
                : object.type === 2
                ? 'Monto fijo'
                : object.type === 3
                ? 'Retainer'
                : object.type === 4
                ? 'Hitos por actividad'
                : object.type === 5
                ? 'Hitos por fecha'
                : object.type === 6
                ? 'Monto tope'
                : object.type === 7
                ? 'Por servicio'
                : object.type === 8
                ? 'No facturable'
                : object.type === 9
                ? 'Mixto'
                : 'No existe'
        }`;
    });
    commit('setTradeAgreements', setData);
    return setData;
};

export const getTradeAgreementsByProyectId = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Proyects/GetTradeAgreementsByProyectId/${id}`,
    );
    const setData = [...data.data];
    await setData.forEach((object) => {
        object.nameType = `${
            object.type === 1
                ? 'Por hora'
                : object.type === 2
                ? 'Monto fijo'
                : object.type === 3
                ? 'Retainer'
                : object.type === 4
                ? 'Hitos por actividad'
                : object.type === 5
                ? 'Hitos por fecha'
                : object.type === 6
                ? 'Monto Tope'
                : object.type === 7
                ? 'Por servicio'
                : object.type === 8
                ? 'No facturable'
                : object.type === 9
                ? 'Mixto'
                : 'No existe'
        }`;
    });
    commit('setTradeAgreements', setData);
    return setData;
};

export const CheckClientsOfProfileTax = async ({ commit }, profileTaxId) => {
    const { data } = await managementApi.get(
        `Clients/CheckClientsOfProfileTax/${profileTaxId}`,
    );
    return data.data;
};

export const putClient = async ({ commit }, datatosend) => {
    const { data } = await managementApi.put('Clients', datatosend);
    commit('resultPut', data);
    return data.data;
};

export const GetUsersWithCategoryByCompanyId = async (
    { commit },
    datatosend,
) => {
    const { data } = await managementApi.post(
        'User/GetUsersWithCategoryByCompanyId',
        datatosend,
    );
    return data.data;
};

export const deleteNewField = async ({ commit, state }, field) => {
    const { data } = await managementApi.delete('Field', { data: field });
    return data.data;
};

export const getTradeAgreementById = async ({ commit }, obj) => {
    const { data } = await managementApi.get(`TradeAgreement/${obj.id}`);
    if (obj.setInfo === true) {
        commit('setTradeAgreementForm', data.data);
    }
    return data.data;
};

export const putTradeAgreement = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.put(`TradeAgreement`, dataToSend);
    return data.data;
};

export const getWorkflowsBySubareas = async ({ commit }, setData) => {
    const { data } = await managementApi.post(
        `Workflows/GetWorkFlowsBySubAreas`,
        setData,
    );
    return data.data;
};

export const getOnlyContactsFullInfo = async ({ commit, rootState }, dts) => {
    const { data } = await managementApi.get(
        `User/GetUsersByCompanyOnlyContact/${dts.id}/${dts.clientId}`,
    );
    data.data.forEach((contact) => {
        contact.typeContactName =
            contact.type_Con === 1
                ? 'Facturación'
                : contact.type_Con === 2
                ? 'Producción'
                : contact.type_Con === 3
                ? 'Comercial'
                : 'No es contacto';
    });
    commit('setDynamicContactsFields', data.data);
    commit('setContacts', data.data);
    return data.data;
};

export const getCategoriesByCompany = async ({ commit }, id) => {
    const { data } = await managementApi.get(`Categories/consCompany/${id}`);
    commit('setCategories', data.data);
};

export const getContactsByCompany = async ({ commit }, guidCompany) => {
    const response = await managementApi
        .get(`User/UsersByCompanyId/${guidCompany}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            return { success: false, codError: 1, data: null };
        });
    let setData = response.data;
    await setData.forEach((object) => {
        object.nameTypeContact = `${
            object.type_Con === 1
                ? 'Facturación'
                : object.type === 2
                ? 'Producción'
                : object.type === 3
                ? 'Comercial'
                : 'No es contacto'
        }`;
    });
    setData = setData.filter((object) => object.iscontact === true);
    commit('setContacts', setData);
    return setData;
};
export const GetActivitiesHitos = async ({ commit }, flujos) => {
    let allActivities = [];
    flujos.forEach((wf) => {
        let acti = wf.activities.map((item) => {
            return {
                id: item.id,
                title:
                    typeof item.title !== 'undefined' ? item.title : item.name,
                idWorkflow: wf.id,
                isLoop: item.isInLoop != true ? false : true,
                boolLoopPaymentTimes:
                    item.boolLoopPaymentTimes != true ? false : true,
            };
        });

        allActivities = allActivities.concat(acti);
    });
    commit('SetHitosActivitiesOption', allActivities);
    return allActivities;
};

export const getConfigCompany = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `Companies/getConfigCompany/${id}`,
    );
    commit('setConfigCompanyById', data.data);
    return data.data;
};

export const CreateCategory = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(`Categories`, dataToSend);
    commit('resultPut', data);
    return data;
};

export const UpdateCategory = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.put(`Categories`, dataToSend);
    commit('resultPut', data);
    return data;
};

export const DeleteCategory = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.delete(`Categories`, {
        data: dataToSend,
    });
    commit('resultPut', data);
    return data;
};

export const CreateTypeExpense = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(`Expenses`, dataToSend);
    commit('resultPut', data);
    return data;
};

export const UpdateTypeExpense = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.put(`Expenses`, dataToSend);
    commit('resultPut', data);
    return data;
};

export const DeleteTypeExpense = async ({ commit }, id) => {
    const { data } = await managementApi.delete(`Expenses`, { data: { id } });
    commit('resultPut', data);
    return data;
};

export const getCurrencies = async ({ commit }) => {
    const { data } = await organizationApi.get(`Currencies`);
    commit('getcurrenciesOption', data.data);
};
export const getcurrency = async ({ commit }, GuidCompany) => {
    const { data } = await settlementApi.post(
        `CurrencyAndIndexingUnit/GetCurrencyByCompanyId`,
        GuidCompany,
    );
    commit('GetCurrency', data.data);
    return data.data;
};
export const getindexingunit = async ({ commit }, GuidCompany) => {
    const { data } = await settlementApi.post(
        `CurrencyAndIndexingUnit/GetIndexingByCompanyId`,
        GuidCompany,
    );
    commit('Getindexingunit', data.data);
};
export const postcurrencyandindexingunit = async ({ commit }, setdata) => {
    const { data } = await settlementApi.post(
        `currencyAndIndexingunit`,
        setdata,
    );
    // commit('GetCurrency', data.data);
    commit('resultPut', data);
    return data;
};
export const putcurrencyandindexingunit = async ({ commit }, setdata) => {
    const { data } = await settlementApi.put(
        `currencyAndIndexingunit`,
        setdata,
    );
    // commit('GetCurrency', data.data);
    commit('resultPut', data);
};
export const Deletecurrencyandindexingunit = async ({ commit }, setdata) => {
    const newdata = { id: setdata };
    const { data } = await settlementApi.delete(`CurrencyAndIndexingUnit`, {
        data: newdata,
    });
};
export const Getindexingbyid = async ({ commit }, GuidIndexingId) => {
    const { data } = await settlementApi.get(
        `CurrencyAndIndexingUnit/${GuidIndexingId}`,
    );
    commit('Getindexingbyid', data.data);
};
// Areas - Practice
// GetAllAreas
export const getpracticesobjlist = async ({ commit }, Idcompany) => {
    const { data } = await managementApi.get(
        `Areas/GetAreasByCompanyId/${Idcompany}`,
    );
    commit('getPracticesAll', data.data);
};
export const getobjpracticesbyid = async ({ commit }, idarea) => {
    const { data } = await managementApi.get(`Areas/${idarea}`);
    commit('getPracticesbyid', data.data);
};
export const postpractices = async ({ commit }, setdata) => {
    const { data } = await managementApi.post(`Areas`, setdata);
    commit('resultPut', data);
};
export const putpractices = async ({ commit }, setdata) => {
    const { data } = await managementApi.put(`Areas`, setdata);
    commit('resultPut', data);
    return data?.data;
};
export const getUserByWorkgroupForTariffView = async ({ commit }, ids) => {
    const { data } = await managementApi.post(
        'WorkGroup/GetUsersByGroups',
        ids,
    );
    const setData = [];
    await data.data.forEach((object) => {
        object.users.forEach((user) => {
            setData.push({
                id: user.id,
                userName: user.userName,
                category: user.category,
                areas: user.areasBySubAreasUser,
            });
        });
    });
    let filterData = await [...new Set(setData)];
    return filterData;
};

export const GetWorkgroupsByArrayId = async ({ commit }, ids) => {
    const { data } = await managementApi.post(
        `WorkGroup/GetWorkgroupsByArrayId`,
        ids,
    );
    return data.data;
};
// Get subareas
export const getsubareaslist = async ({ commit }, idCompany) => {
    const { data } = await managementApi.get(
        `Areas/GetSubareasbycompany?company=${idCompany}`,
    );
    commit('getsubareas', data.data);
};
export const getareaslist = async ({ commit }, idCompany) => {
    const { data } = await managementApi.get(
        `Areas/GetAreasSelect?company=${idCompany}`,
    );
    commit('getallareas', data.data);
};
export const getareabyid = async ({ commit }, idarea) => {
    const { data } = await managementApi.get(`Areas/${idarea}`);
    commit('getareabyid', data.data);
};
export const postsubareas = async ({ commit }, setdata) => {
    const { data } = await managementApi.post(`Areas`, setdata);
    commit('resultPut', data);
};
export const putsubareas = async ({ commit }, setdata) => {
    const { data } = await managementApi.put(`Areas`, setdata);
    commit('resultPut', data);
};
export const getAllCurrencyAndIndexing = async ({ commit }, id) => {
    const { data } = await settlementApi.get(`CurrencyAndIndexingUnit/${id}`);
    return data.data;
};
export const postfilesgeneratebyaws = async ({ commit, state }, file) => {
    const data = await filesApi.post(`FilesS3/Uploadfile`, file);
    return data.data;
};

export const analyzeEmailFiles = async ({ commit, state }, file) => {
    const data = await filesApi.post(`FilesS3/AnalyzeEmailFiles`, file);
    return data.data;
};

export const getallfilesloaded = async ({ commit }, ids) => {
    const { data } = await managementApi.post(`FilesS3/FilesS3byIds`, ids);
    commit('getfilesloaded', data.data);
    return data.data;
};

export const getFilesByIdFilesArray = async ({ commit }, ids) => {
    const { data } = await managementApi.post(
        `FilesS3/filesbyArrayIdFiles`,
        ids,
    );
    return data.data;
};

export const postFilesS3 = async ({ commit }, ObjFilesS3) => {
    const { data } = await managementApi.post(`FilesS3`, ObjFilesS3);
    commit('setFilesS3Added', data);
    return data.data;
};
export const postFilesListS3 = async ({ commit }, ObjFilesS3) => {
    const { data } = await managementApi.post(
        `FilesS3/CreateFileList`,
        ObjFilesS3,
    );
    //commit('setFilesS3Added', data);
};

export const putSystemForm = async ({ commit }, systemForm) => {
    const { data } = await managementApi.put(`SystemForms`, systemForm);
    return data.data;
};

export const getAreaSubAreaByActivityId = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `Activities/GetActivityAreaSubArea/${dataToSend.activity}/${dataToSend.subarea}`,
    );
    commit('setAreaSubAreaByActivityId', data);
    return data.data;
};

export const getCurrentCompaniesByUser = async ({ commit }, user) => {
    const { data } = await managementApi.get(
        `Companies/getCompaniesByUser/${user}`,
    );
    commit('setCurrentCompaniesByUser', data);
    return data.data;
};

export const deleteFileS3 = async ({ commit }, nameFile) => {
    const { data } = await managementApi.post(
        `/FilesS3/DeletefileS3`,
        nameFile,
    );
    return data;
};

export const getCompanyHolidays = async ({ commit }, companyId) => {
    const { data } = await managementApi.get(
        `Companies/getCompanyHolidays/${companyId}`,
    );
    commit('setCompanyHolidays', data.data);
    return data.data;
};

export const getRolesById = async ({ commit }, ids) => {
    const { data } = await managementApi.post(`Roles/Getpermisos`, ids);
    let permissions =
        data.data.length > 0
            ? data.data.filter((rol) => {
                  if (rol.length > 0) {
                      return rol;
                  }
              })
            : [];
    let newPermissions = [];
    permissions.map((permission) => {
        permission.forEach((permissionCode) => {
            newPermissions = [...newPermissions, permissionCode.code];
        });
    });
    commit('auth/setUserDBPermissions', newPermissions, { root: true });
};

export const GetFilesByWorkflowId = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `FilesS3/GetFilesByWorkflowId/${id}`,
    );
    return data.data;
};

export const GetFilesByWorkflowIdClone = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `FilesS3/GetFilesByWorkflowIdClone/${id}`,
    );
    return data.data;
};

export const getNotificationsByUser = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `User/GetNotificationsByUser/${id}`,
    );
    commit('setNotificationTabUser', data.data);
    return data.data;
};

export const updateListNotificationsUser = async ({ commit }, sendToData) => {
    const { data } = await managementApi.put(
        `User/UpdateNotificationsUser`,
        sendToData,
    );
    return data.data;
};
export const createChangesHistory = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(`ChangesHistory`, dataToSend);
    // commit('management/resultPut', data, { root: true });
    return data.data;
};

export const getDifferencesTradeAgreements = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.get(
        `TradeAgreement/RegisterDifferencesTrade/${dataToSend.idBefore}/${dataToSend.idAfter}/${dataToSend.workFlowOptId}`,
    );
    return data.data;
};

export const getUserByIdByActivities = async ({ commit }, id) => {
    const { data } = await managementApi.get(`User/${id}`);
    return data.data;
};

export const getUsersByCompanyExternalUsers = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `User/GetUsersByCompanyExternalUsers/${id}`,
    );
    commit('setExternalDataUsers', data.data);
    return data.data;
};

export const getDynamicFieldsNames = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `Field/getEntireFieldById`,
        dataToSend,
    );
    commit('setDynamicFieldsNames', data.data);
    return data.data;
};

export const GetFilesByProjectId = async ({ commit }, id) => {
    const { data } = await managementApi.get(`FilesS3/GetOptProyectId/${id}`);
    return data.data;
};

export const getUsersOutlook = async ({ commit }, companyId) => {
    const { data } = await managementApi.get(
        `User/GetUsersOutlook/${companyId}`,
    );
    commit('setUsersOutlook', data.data);
};

export const postfilesgeneratebyawsEmailOutlook = async (
    { commit, state },
    file,
) => {
    const data = await filesApi.post(`FilesS3/UploadfileEmail`, file);
    return data.data;
};
export const getReportsDynamics = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `DynamicForms/GetDynamicFormsReports/${id}`,
    );
    commit('setDynamicReports', data.data);
    return data.data;
};
export const PostReasignManagment = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `User/PostInsertJob/`,
        dataToSend,
    );
    return data.data;
};

export const PostReasignProduction = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/PostReasignProduction`,
        dataToSend,
    );
    return data.data;
};
export const getDocumentsByCaseId = async ({ commit }, dataSend) => {
    const { data } = await managementApi.post(
        `FilesS3/GetDocumentsByCaseId`,
        dataSend,
    );
    commit('setDocumentsByCase', data.data);
    return data.data;
};

export const ActivateProjectAll = async ({ commit }, config) => {
    const { GuidProject } = config;
    const { data } = await managementApi.post(
        `Proyects/ActivateProjectAll/${GuidProject}`,
    );
    if (data?.data?.isInactiveClientActive) {
        commit('setMessageInactiveClient', true);
        return;
    }
    //Activando bloques de liquidación de acuerdos comerciales del proyecto
    await settlementApi.post(
        'OABUAwaitingPreinvoiceBlocks/ActivateBlocksTradeAgreements',
        data?.data,
    );
    //Activando flujos, actividades y tareas
    const response = await productionApi.post(
        'OperativeWorkFlows/ActivateWorkflowsGeneric',
        data?.data,
    );

    if (response) {
        //Activando los acuerdos comerciales de flujos
        await settlementApi.post(
            'OABUAwaitingPreinvoiceBlocks/ActivateBlocksTradeAgreements',
            response.data?.data,
        );
    }

    return data?.data;
};

export const DeactivateProjectAll = async ({ commit }, config) => {
    const { GuidProject } = config;
    const { data } = await managementApi.post(
        `Proyects/DeactivateProjectAll/${GuidProject}`,
    );
    //desactivando bloques de liquidación de acuerdos comerciales del proyecto
    await settlementApi.post(
        'OABUAwaitingPreinvoiceBlocks/UpdateBlocksTradeAgreements',
        data?.data,
    );
    //desactivando flujos, actividades y tareas
    const response = await productionApi.post(
        'OperativeWorkFlows/DeactivateWorkflowsGeneric',
        data?.data,
    );

    if (response) {
        //desactivando los acuerdos comerciales de flujos
        await settlementApi.post(
            'OABUAwaitingPreinvoiceBlocks/UpdateBlocksTradeAgreements',
            response.data?.data,
        );
    }

    return data?.data;
};

export const ShutdownProject = async ({ commit }, config) => {
    const { GuidProject } = config;
    const { data } = await managementApi.post(
        `Proyects/ShutdownProyect/${GuidProject}`,
    );
    return data?.data;
};

export const verifyFile = async ({ commit }, fileData) => {
    const { data } = await managementApi.post(`FilesS3/VerifyFile`, fileData);
    return data.data;
};

export const closeTradeAgreementById = async ({ commit }, tradeAgreementId) => {
    const { data } = await managementApi.get(
        `TradeAgreement/CloseTradeAgreementById/${tradeAgreementId}`,
    );
    return data.data;
};

export const getCompanyImageById = async ({ commit }, companyId) => {
    const { data } = await managementApi.get(
        `Companies/GetCompanyImageById/${companyId}`,
    );
    return data.data;
};
export const GetTradeAgreementActiveIdByProjectId = async (
    { commit },
    proyectId,
) => {
    const { data } = await managementApi.get(
        `Proyects/GetTradeAgreementActiveIdByProjectId/${proyectId}`,
    );
    return data.data;
};

export const GetClientsByCompanyIdForDropdows = async (
    { commit },
    companyId,
) => {
    const { data } = await managementApi.get(
        `Clients/GetClientsByCompanyIdForDropdows/${companyId}`,
    );
    return data.data;
};

export const GetProyectsInfoByClientIdForDropdowns = async (
    { commit },
    clientId,
) => {
    const { data } = await managementApi.get(
        `Proyects/GetProyectInfoForDropdowns/${clientId}`,
    );
    return data.data;
};

export const GetInfoClientsForSendToPreinvoiceProcess = async (
    { commit },
    dataToSend,
) => {
    const { data } = await managementApi.post(
        `Clients/GetInfoClientsForSendToPreinvoiceProcess`,
        dataToSend,
    );
    return data.data;
};

export const GetDashboardByClientOrNot = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.get(
        `Clients/GetDashboardByClientOrNot/${dataToSend.companyId}/${dataToSend.idClient}`,
    );
    return data.data;
};

export const GetProjectByIdGrid = async ({ commit }, dts) => {
    const { data } = await managementApi.post(
        `Proyects/GetProjectByIdGrid`,
        dts,
    );
    commit('setProjects', data.data);
};

export const GetDashTradeAgreementGridByClient = async (
    { commit },
    idClient,
) => {
    const { data } = await managementApi.get(
        `TradeAgreementDashboardGrid/GetDashTradeAgreementGridByClient/${idClient}`,
    );
    return data.data;
};

export const GetClientInfoDash = async ({ commit }, idClient) => {
    const { data } = await managementApi.get(
        `Clients/GetClientInfoDash/${idClient}`,
    );
    return data.data;
};
export const getProyectsByClientContacts = async ({ commit }, clientId) => {
    const { data } = await managementApi.get(
        `Proyects/GetProyectsByClientContacts/${clientId}`,
    );
    commit('setProjectsByContacts', data.data);
};

export const getProjectsByContact = async ({ commit }, objtParam) => {
    const { data } = await managementApi.get(
        `User/GetProjectsByContact/${objtParam.contactId}/${objtParam.clientId}`,
    );
    commit('setProjectsSelectedByContact', data.data);
    return data.data;
};

export const UpdateAccessContact = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `User/UpdateProjectByContact`,
        dataToSend,
    );
    return data.data;
};

export const BreakRelationContact = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `User/BreakRelationContact`,
        dataToSend,
    );
    return data.data;
};
export const CreateEmailExternsByCompany = async ({ commit }, request) => {
    const { data } = await managementApi.post(
        'EmailExterns/CreateEmailExterns',
        request,
    );
    return data.data;
};

export const GetEmailExternsByCompany = async ({ commit }, IdCompany) => {
    const { data } = await managementApi.get(
        `EmailExterns/GetEmailExternsByCompany/${IdCompany}`,
    );
    return data.data;
};

export const GetWorkgroupsByUserId = async ({ commit }, IdUser) => {
    const { data } = await managementApi.get(
        `Workgroup/GetWorkgroupsByUserId/${IdUser}`,
    );
    return data.data;
};

export const postFilesActivityS3 = async ({ commit }, ObjFilesS3) => {
    const { data } = await managementApi.post(
        `FilesS3/CreateFileFromActivity`,
        ObjFilesS3,
    );
    let resultData = data.data;
    data.data = data.data.id;
    commit('setFilesS3Added', data);
    return resultData;
};

export const GetTaxesByCountryId = async ({ commit }, companyId) => {
    const { data } = await managementApi.get(
        `InfoTaxesByCountry/GetTaxesByCountry/${companyId}`,
    );
    commit('setTaxesByCountry', data.data);
    return data.data;
};

export const ReOpenTradaeAgreementAboutOperativeWorkflow = async (
    { commit },
    tradeAgreementId,
) => {
    const { data } = await managementApi.post(
        `TradeAgreement/ReOpenTradaeAgreementAboutOperativeWorkflow/${tradeAgreementId}`,
    );
    return data.data;
};

export const getFiledByIdsByClientId = async ({ commit }, sendData) => {
    const { data } = await managementApi.post(
        `Field/fieldsbyArrayIdsByClient`,
        sendData,
    );
    return data.data;
};

export const GetProyectFilter = async ({ commit }, dataToSend) => {
    const { data } = await managementApi.post(
        `Proyects/GetProyectFilter`,
        dataToSend,
    );
    return data.data;
};

export const deleteMemberFromWorkGroup = async ({ commit }, info) => {
    const { data } = await managementApi.get(
        `WorkGroup/DeleteMemberFromWorkGroup/${info.userId}/${info.workgroupId}`,
    );
    return data.data;
};

export const isFatherInAnyWorkGroup = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `WorkGroup/isFatherInAnyWorkGroup/${id}`,
    );
    return data.data;
};

export const DeactivateUser = async ({ commit }, id) => {
    const { data } = await managementApi.get(
        `User/DeactivateUser/${id}`,
    );
    return data.data;
};
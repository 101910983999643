export const setCurrencyAndIndexingUnitByCompany = (
    state,
    currencyAndIndexingUnitByCompany,
) => {
    state.currencyAndIndexingUnitByCompany = currencyAndIndexingUnitByCompany;
};

export const setActivitiesByProjectToGridAuditedByLeader = (
    state,
    activitiesByProjectToGridAuditedByLeader,
) => {
    state.activitiesByProjectToGridAuditedByLeader =
        activitiesByProjectToGridAuditedByLeader;
};

export const setGrid = (state, grid) => {
    state.grid = grid;
};

export const setRateBooks = (state, rateBooks) => {
    state.rateBooks = rateBooks;
};

export const setActivitiesByProjectToGridAuditedByLeaderDetail = (
    state,
    activitiesByProjectToGridAuditedByLeaderDetail,
) => {
    state.activitiesByProjectToGridAuditedByLeaderDetail =
        activitiesByProjectToGridAuditedByLeaderDetail;
};

export const setOperativeActivityByUserIdToBillingNotes = (
    state,
    operativeActivityByUserIdToBillingNotes,
) => {
    state.operativeActivityByUserIdToBillingNotes =
        operativeActivityByUserIdToBillingNotes;
};

export const setOperativeActivityByUserNameToBillingNotes = (
    state,
    operativeActivityByUserNameToBillingNotes,
) => {
    state.operativeActivityByUserNameToBillingNotes =
        operativeActivityByUserNameToBillingNotes;
};

export const setOABUAuditedByLeaderIdArray = (
    state,
    OABUAuditedByLeaderIdArray,
) => {
    state.OABUAuditedByLeaderIdArray = OABUAuditedByLeaderIdArray;
};

export const setShowSideTab = (state, showSideTab) => {
    state.showSideTab = showSideTab;
};

export const setBlocksToSettle = (state, blocksToSettle) => {
    state.blocksToSettle = blocksToSettle;
};

export const setGridSummaryData = (state, items) => {
    state.gridSummaryData = items;
};

export const setGridSummaryComponent = (state, gridSummaryComponent) => {
    state.gridSummaryComponent = gridSummaryComponent;
};

export const setPreinvoice = (state, preinvoice) => {
    state.preinvoice = preinvoice;
};

export const setBilling = (state, billing) => {
    state.billing = billing;
};

export const setInvoicesByClient = (state, invoicesByClient) => {
    state.invoicesByClient = invoicesByClient;
};

export const setGridSummaryFeeEffectiveByHour = (
    state,
    gridSummaryFeeEffectiveByHour,
) => {
    state.gridSummaryFeeEffectiveByHour = gridSummaryFeeEffectiveByHour;
};

export const setUnitOfPaymentConvention = (state, unitOfPaymentConvention) => {
    state.unitOfPaymentConvention = unitOfPaymentConvention;
};

export const setFormatCurrencyShowModified = (
    state,
    formatCurrencyShowModified,
) => {
    state.formatCurrencyShowModified = formatCurrencyShowModified;
};

export const setInfoUnitIndexingValuesGlobal = (
    state,
    infoUnitIndexingValuesGlobal,
) => {
    state.infoUnitIndexingValuesGlobal = infoUnitIndexingValuesGlobal;
};

export const setShowWalkthrough = (state, showWalkthrough) => {
    state.showWalkthrough = showWalkthrough;
};

export const setAditionalComments = (state, aditionalComments) => {
    state.aditionalComments = aditionalComments;
};

export const setOABUAuditedByLeaderIndicator = (
    state,
    OABUAuditedByLeaderIndicator,
) => {
    state.OABUAuditedByLeaderIndicator = OABUAuditedByLeaderIndicator;
};

export const setOABUAwaitingPreinvoiceBlocksIndicator = (
    state,
    OABUAwaitingPreinvoiceBlocksIndicator,
) => {
    state.OABUAwaitingPreinvoiceBlocksIndicator =
        OABUAwaitingPreinvoiceBlocksIndicator;
};
export const setPreinvoiceByProjectIndicator = (
    state,
    PreinvoiceByProjectIndicator,
) => {
    state.PreinvoiceByProjectIndicator = PreinvoiceByProjectIndicator;
};

export const setBillingIndicator = (state, BillingIndicator) => {
    state.BillingIndicator = BillingIndicator;
};

export const setActivitiesSummary = (state, activitiesSummary) => {
    state.activitiesSummary = activitiesSummary;
};

export const setNewBillingNote = (state, newBillingNote) => {
    state.newBillingNote = newBillingNote;
};

export const setMonthOfPreinvoice = (state, monthOfPreinvoice) => {
    state.monthOfPreinvoice = monthOfPreinvoice;
};

export const setcancelProcessFlag = (state, cancelProcessFlag) => {
    state.cancelProcessFlag = cancelProcessFlag;
};

export const setSendActivitiesToPreinvoiceFlag = (
    state,
    sendActivitiesToPreinvoiceFlag,
) => {
    state.sendActivitiesToPreinvoiceFlag = sendActivitiesToPreinvoiceFlag;
};
export const setSendActivitiesToPreinvoiceInterFlag = (
    state,
    sendActivitiesToPreinvoiceInterFlag,
) => {
    state.sendActivitiesToPreinvoiceInterFlag =
        sendActivitiesToPreinvoiceInterFlag;
};
export const setSendBlocksFlag = (state, sendBlocksFlag) => {
    state.sendBlocksFlag = sendBlocksFlag;
};
export const setSendBlockSeparateFlag = (state, sendBlockSeparateFlag) => {
    state.sendBlockSeparateFlag = sendBlockSeparateFlag;
};
export const setSendBlocksInterFlag = (state, sendBlocksInterFlag) => {
    state.sendBlocksInterFlag = sendBlocksInterFlag;
};
export const setSendBlockSeparateInterFlag = (
    state,
    sendBlockSeparateInterFlag,
) => {
    state.sendBlockSeparateInterFlag = sendBlockSeparateInterFlag;
};
export const setShutDownBlocksOnlyFlag = (state, shutDownBlocksOnlyFlag) => {
    state.shutDownBlocksOnlyFlag = shutDownBlocksOnlyFlag;
};
export const setShutDownBlocksFlag = (state, shutDownBlocksFlag) => {
    state.shutDownBlocksFlag = shutDownBlocksFlag;
};
export const setAprovePreinvoiceOnlyFlag = (
    state,
    aprovePreinvoiceOnlyFlag,
) => {
    state.aprovePreinvoiceOnlyFlag = aprovePreinvoiceOnlyFlag;
};
export const setAprovePreinvoiceFlag = (state, aprovePreinvoiceFla) => {
    state.aprovePreinvoiceFla = aprovePreinvoiceFla;
};
export const setMoneyIndicators = (state, moneyIndicators) => {
    state.moneyIndicators = moneyIndicators;
};
export const setPreinvoiceByProjectMoneyIndicators = (
    state,
    preinvoiceByProjectMoneyIndicators,
) => {
    state.preinvoiceByProjectMoneyIndicators =
        preinvoiceByProjectMoneyIndicators;
};

export const setIntervalValues = (state, values) => {
    state.intervalsValues = values;
};
export const setActivateBlockFlag = (state, activateBlockFlag) => {
    state.activateBlockFlag = activateBlockFlag;
};
export const setInvoicesByProject = (state, invoicesByProject) => {
    state.invoicesByProject = invoicesByProject;
};

export const setResetPreinvoice = (state, resetPreinvoice) => {
    state.resetPreinvoice = resetPreinvoice;
};
export const setCommentPreinvoice = (state, commentPreinvoice) => {
    state.commentPreinvoice = commentPreinvoice;
};

export const setCancelBillingFlag = (state, cancelBillingFlag) => {
    state.cancelBillingFlag = cancelBillingFlag;
};

export const setCancelPreinvoiceFlag = (state, cancelPreinvoiceFlag) => {
    state.cancelPreinvoiceFlag = cancelPreinvoiceFlag;
};
export const setTradeAgreementGridPDF = (state, tradeAgreementGridPDF) => {
    state.tradeAgreementGridPDF = tradeAgreementGridPDF;
};
export const setExportGridsFlag = (state, exportGridsFlag) => {
    state.exportGridsFlag = exportGridsFlag;
};
export const setExportGridInfo = (state, exportGridInfo) => {
    state.exportGridInfo = exportGridInfo;
};

export const setClientsForDropdown = (state, clientsForDropdown) => {
    state.clientsForDropdown = clientsForDropdown;
};

export const setChangeFromGrid = (state, changeFromGrid) => {
    state.changeFromGrid = changeFromGrid;
};
export const setCreditNoteId = (state, creditNoteId) => {
    state.creditNoteId = creditNoteId;
};

export const setCashRegisterReceiptId = (state, cashRegisterReceiptId) => {
    state.cashRegisterReceiptId = cashRegisterReceiptId;
};
export const setNoovaBillingState = (state, dataNoova) => {
    state.noovaState = dataNoova;
};
export const setCreateCreditNote = (state, valueCreateNcParam) => {
    state.valueCreateNc = valueCreateNcParam;
};

export const setInfoLogicBlocksState = (state, infoLogicBlocksState) => {
    state.infoLogicBlocksState = infoLogicBlocksState;
};
